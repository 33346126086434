import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Hero from '../components/Hero'
import Stats from '../components/Stats'
import Guarantee from '../components/Guarantee'
import Features from '../components/Features'
import Newsletter from '../components/Newsletter'
import Procedure from '../components/Procedure'

const HomePage = () => {
  return (
    <div className="flex-1 w-full overflow-hidden bg-primary">
      <div className="flex sm:px-16 px-6 justify-center items-center">
        <div className="w-full xl:max-w-[1280px]">
          <Navbar />
        </div>
      </div>

      <div className="flex sm:px-6 px-6 justify-center items-center">
        <div className="w-full xl:max-w-[1280px]">
          {/* Your Homepage Content */}
          <Hero/>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="w-full xl:max-w-[1280px]">
          {/* Your Homepage Content */}
          <Stats/>
        </div>
      </div>
      <div className="flex sm:px-6 px-6 justify-center items-center">
        <div className="w-full xl:max-w-[1280px]">
          {/* Your Homepage Content */}
          <Features/>
        </div>
      </div>
      <div className="flex sm:px-6 px-6 sm:py-16 justify-center items-center">
        <div className="w-full xl:max-w-[1280px]">
          {/* Your Homepage Content */}
          <Guarantee/>
        </div>
      </div>
      <div className="flex justify-center items-center" id='use'>
        <div className="w-full xl:max-w-[1280px]">
          {/* Your Homepage Content */}
          <Procedure/>
        </div>
      </div>
      
      <div className="flex justify-center items-center sm:py-12 py-6">
        <div className="w-full xl:max-w-[1280px]">
          {/* Your Homepage Content */}
          <Newsletter/>
        </div>
      </div>
      
      
      <div className="flex sm:px-6 px-6 justify-center items-center">
        <div className="w-full xl:max-w-[1280px]">
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default HomePage
