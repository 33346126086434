import React from "react";

const Procedure = () => {
  return (
    <div>
      <section id="use" class="relative bg-white lg:py-24">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="max-w-2xl mx-auto text-center">
            <h2 class="text-4xl text-black font-extrabold mx-auto md:text-6xl lg:text-5xl">
              How does it work?
            </h2>
            <p class="max-w-2xl mx-auto mt-4 text-base text-gray-400 leading-relaxed md:text-2xl">
              Simple & Interractive.
            </p>
          </div>
          <div class="relative mt-12 lg:mt-20">
            <div class="absolute inset-x-0 hidden xl:px-44 top-2 md:block md:px-20 lg:px-28">
              <img
                alt=""
                loading="lazy"
                width="1000"
                height="500"
                decoding="async"
                data-nimg="1"
                class="w-full"
                src="https://cdn.rareblocks.xyz/collection/celebration/images/steps/2/curved-dotted-line.svg"
              />
            </div>
            <div class="relative grid grid-cols-1 text-center gap-y-12 md:grid-cols-3 gap-x-12">
              <div>
                <div class="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                  <span class="text-xl font-semibold text-gray-700">
                    <img src="images/downloading.png" className="h-12 w-12" alt="" />
                  </span>
                </div>
                <h3 class="mt-6 text-xl  text-slate-500 font-semibold leading-tight md:mt-10">
                  Download App
                </h3>
                <p class="mt-4 text-base text-gray-400 md:text-lg">
                  Go to playstore or App store and download App.
                </p>
              </div>
              <div>
                <div class="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                  <span class="text-xl font-semibold text-gray-700">
                  <img src="images/account.png" className="h-12 w-12" alt="" />
                  </span>
                </div>
                <h3 class="mt-6 text-xl text-slate-600 font-semibold leading-tight md:mt-10">
                  Create your Account.
                </h3>
                <p class="mt-4 text-base text-gray-400 md:text-lg">
                  Fill in your details to setup an Account with us..
                </p>
              </div>
              <div>
                <div class="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                  <span class="text-xl font-semibold text-gray-700">
                  <img src="images/use.png" className="h-12 w-12" alt="" />
                  </span>
                </div>
                <h3 class="mt-6 text-xl text-slate-600 font-semibold leading-tight md:mt-10">
                  Use App
                </h3>
                <p class="mt-4 text-base text-gray-400 md:text-lg">
                  Use the App.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="absolute inset-0 m-auto max-w-xs h-[357px] blur-[118px] sm:max-w-md md:max-w-lg"
          
        ></div>
      </section>
    </div>
  );
};

export default Procedure;
