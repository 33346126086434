import React from "react";
import * as Icon from 'react-feather';

const Hero = () => {
  return (
    <div>
      <div class="bg-white h-full py-20">
        <div class="container mx-auto">
          <div class="flex flex-col md:flex-row items-center">
            <div class="md:w-1/2 lg:w-2/3">
              <h1 class="text-3xl md:text-5xl lg:text-5xl text-black font-bold mb-6">
                The Best Way to get <br class="hidden md:block" />
                <span class="text-red-500">Wherever</span> You are Going To.
              </h1>
              <p class="text-lg md:text-xl lg:text-2xl text-gray-400 mb-8">
                We create beautiful and functional websites for businesses of
                all sizes.
              </p>
              <div class="flex gap-2">
                <a
                  href="#"
                  class="bg-red-500 hover:bg-red-400 text-white font-bold py-3 px-6 rounded-md"
                >
                  Get Started
                </a>
                <a
                  href="#"
                  class="bg-black flex flex-row items-center space-x-4 text-white hover:bg-black text-black font-bold py-3 px-6 rounded-md"
                >
                  Download App
                  <Icon.Download size={25} color="white" />
                </a>
              </div>
            </div>
            <div class="md:w-1/2 lg:w-1/3 mt-8 md:mt-0">
              <img
                src="images/sig.png"
                alt="Hero Image"
                class="rounded-lg shadow-lg"
              />
            </div>
          </div>
          <div class="flex items-end max-w-screen-lg mx-auto md:py-16">
      <div class="w-full">
        <h6 class="text-base font-bold text-red-500 mb-2 mr-4">Request Ride</h6>
        <div class="flex items-center w-full">
          <div
            class="w-8 h-8 shrink-0 mx-[-1px] border-2 border-red-500 p-1.5 flex items-center justify-center rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-full fill-red-500" viewBox="0 0 24 24">
              <path
                d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                data-original="#000000" />
            </svg>
          </div>
          <div class="w-full h-1 bg-red-500"></div>
        </div>
      </div>
      <div class="w-full">
        <h6 class="text-base font-bold text-red-500 mb-2 mr-4">Find Driver</h6>
        <div class="flex items-center w-full">
          <div
            class="w-8 h-8 shrink-0 mx-[-1px] border-2 border-red-500 p-1.5 flex items-center justify-center rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-full fill-red-500" viewBox="0 0 24 24">
              <path
                d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                data-original="#000000" />
            </svg>
          </div>
          <div class="w-full h-1 bg-red-500"></div>
        </div>
      </div>
      <div class="w-full">
        <h6 class="text-base font-bold text-red-500 mb-2 mr-4">Start Trip</h6>
        <div class="flex items-center w-full">
          <div
            class="w-8 h-8 shrink-0 mx-[-1px] border-2 border-red-500 p-1.5 flex items-center justify-center rounded-full">
            <span class="w-3 h-3 bg-red-500 rounded-full"></span>
          </div>
          <div class="w-full h-1 bg-gray-300"></div>
        </div>
      </div>
      <div>
        <h6 class="text-base font-bold text-gray-400 w-max mb-2">Safe Ride</h6>
        <div class="flex items-center">
          <div class="w-8 h-8 shrink-0 mx-[-1px] border-2 p-1.5 flex items-center justify-center rounded-full">
            <span class="text-base text-gray-400 font-bold">4</span>
          </div>
        </div>
      </div>
    </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
