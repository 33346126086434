export const navLinks = [
    {
      id: "home",
      name: "Home",
      path: "/",
    },
    {
      id: "about",
      name: "About",
      path: "/about",
    },
    {
      id: "login",
      name: "Login",
      path: "/login",
    },
    
  ];